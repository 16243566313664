<template>
  <v-select
    v-model="company"
    name="company"
    label="Empresa"
    :items="orderedCompanies"
    item-value="id"
    item-text="name"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhuma empresa disponível"
    :loading="loading"
    :clearable="clearable"
    @change="
      changeCompany === true ? companySelected($event) : handleSelect($event)
    "
  />
</template>

<script>

import CompanyService from '@/services/CompanyService';

export default {
  name: 'SelectCompany',
  props: {
    type: {
      type: String,
      default: 'solo',
    },
    changeCompany: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      company: '',
      companies: [],
      loading: true,
    };
  },
  created() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      CompanyService
        .getCompanies()
        .then(companies => this.companies = companies)
        .finally(() => this.loading = false);
    },
    handleSelect(companySelected) {
      this.$emit('selected', companySelected);
    },
    companySelected(event) {
      const companySelected = this.companies.find(company => company.id === event);
      this.$emit('companySelected', companySelected);
    }
  },
  computed: {
    orderedCompanies() {
      return this.companies.sort((a, b) => (a.name < b.name) ? -1 : 1);
    }
  }
}
</script>
